import React from "react";
import { Tooltip, Typography } from '@material-ui/core';
import { useStyles } from './styles';

export default function CardTitle({name}) {
  const classes = useStyles();
  return (
    <Tooltip title={name} placement="bottom-end">
      <Typography
        className={classes.title}
        gutterBottom
        variant="h5"
        component="h2"
      >
        {name}
      </Typography>
    </Tooltip>
  );
}

import React from 'react';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import YTPlayer from 'components/YouTube/YouTubePlayer';
import Clip from './Clip';
import { clipsStyles } from './styles';
import { useLiveQuery } from "dexie-react-hooks";
import db from "db";

const Clips = ({ videoId, playClip, pause }) => {
  const classes = clipsStyles();

  const clips = useLiveQuery(() => {
    return db.clips.where({videoId}).reverse().toArray()
  }, [videoId]);

  const handleClick = () => {
    const name = '';
    const start = YTPlayer.getCurrentTime();
    const end = YTPlayer.getCurrentTime(10);
    db.clips.add({videoId, name, start, end});
  }

  const handleUpdate = (id, name, start, end) => {
    db.clips.update(id, { name, start, end });
  }

  const handleRemove = (id) => {
    db.clips.delete(id);
  }

  return (
    <>
      <Button
        onClick={handleClick}
        className={classes.root}
        variant="contained"
        color="secondary"
        startIcon={<AddCircleIcon />}
      >
        Add New Clip
      </Button>
      { clips &&
        clips.map((clip) => (
          <Clip {...{ key: clip.id, clip, playClip, pause, handleUpdate, handleRemove }} />
        ))
      }
    </>
  );
}

export default Clips;

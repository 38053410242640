import { makeStyles } from '@material-ui/core/styles';

export const clipStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  menu: {
    marginLeft: theme.spacing(2),
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
    },
  },
  input: {
    margin: theme.spacing(1),
    width: '11ch',
  },
  controls: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  times: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 1,
      width: '100%',
      paddingBottom: theme.spacing(2),
    },
  },
  practice: {
    order: 3,
    marginLeft: 'auto',
    [theme.breakpoints.down('sm')]: {
      order: 3,
    },
  },
  history: {
    marginLeft: theme.spacing(1),
  }
}));

export const clipsStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
}))
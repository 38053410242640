import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StopIcon from '@material-ui/icons/Stop';
import { v4 as uuidv4 } from 'uuid';

const Recorder = ({addRecording}) => {
  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recording, setRecording] = useState(false);

  const handleClick = () => {
    if (!recording) {
      navigator.mediaDevices.getUserMedia({ audio: true })
        .then(stream => startRecording(stream))
        .catch(err => console.log(err));
      setRecording(true);
    } else {
      mediaRecorder.stop();
      setRecording(false);
    }
  }

  const onRecordingFinished = (url) => {
    const date = Date.now();
    addRecording({
      id: uuidv4(),
      url,
      date,
      //pronunciation_id: 
    });
  }

  const startRecording = (stream) => {
    const mR = new MediaRecorder(stream);
    mR.ondataavailable = handleDataAvailable;
    mR.start();
    setMediaRecorder(mR);
  }

  const handleDataAvailable = (e) => {
    const blob = new Blob([e.data], { type: 'audio/ogg; codecs=opus' });
    const url = window.URL.createObjectURL(blob);
    onRecordingFinished(url);
  }

  return (
    <>
      <IconButton onClick={handleClick}>
        { !recording
          ? <FiberManualRecordIcon />
          : <StopIcon />
        }
      </IconButton>
    </>
  );
}

export default Recorder;

import React from 'react';
import Pronunciation from './Pronunciation';

const Pronunciations = ({ pronunciations }) => {
  return (
    <>
      { pronunciations &&
        pronunciations.map((pronunciation, i) => (
          <Pronunciation {...{ key: i, pronunciation }} />
        ))
      }
    </>
  )
}

export default Pronunciations;

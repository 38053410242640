import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import { useStyles } from './styles';

export default function Header() {
  const classes = useStyles();
  return (
    <Container className={classes.root} maxWidth="xl">
      <Typography className={classes.title} variant="h3" component="h1">
        <Link component={RouterLink} to="/" color="inherit" underline="none">
          Pronunciate
        </Link>
      </Typography>
    </Container>
  );
}

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  title: {
    // lineHeight:
    //   "1.2em" /* Adjust this value depending on your desired line spacing */,
    // maxHeight:
    //   "2.4em" /* 2 lines, derived from 2 (lines) * 1.2 (line-height) */,
    // whiteSpace: "nowrap",
    color: theme.palette.text.primary,
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2,
    "-webkit-box-orient": "vertical",
  },
}));

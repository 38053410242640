export const Clips = [
    {
        videoId: 'bLFlyCILkG8',
        name: 'Ich hätte gerne ein Kleid für eine Hochzeit. Wie viel macht das?',
        start: '07:52',
        end: '07:56.6',
    },
    {
        videoId: 'bLFlyCILkG8',
        name: 'Ich hätte gerne einen Salat mit Pommes.',
        start: '07:35.25',
        end: '07:39.5',
    },
    {
        videoId: 'bLFlyCILkG8',
        name: 'Wie viel Uhr ist es?',
        start: '06:37.5',
        end: '06:39.5',
    },
    {
        videoId: 'bLFlyCILkG8',
        name: 'Guten Morgen.',
        start: '00:29.5',
        end: '00:30.75',
    },
    {
        videoId: '4-eDoThe6qo',
        name: 'Das ist eine Sehenswürdigkeit in Köln: der Kölner Dom.',
        start: '22:57.8',
        end: '23:01.75',
    },
    {
        videoId: '4-eDoThe6qo',
        name: 'Die Miete kostet mit Nebenkosten 400 Euro im Monat.',
        start: '37:12.5',
        end: '37:16',
    },
];
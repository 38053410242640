import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    marginBottom: theme.spacing(3),
    maxWidth: '40em',
  },
  input: {
    flexGrow: '1',
  },
  button: {
    marginLeft: theme.spacing(1),
  },
}));

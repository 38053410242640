import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import AudioPlayer from 'components/AudioPlayer';
import Recorder from 'components/Recorder';
import History from 'components/History';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { pronunciationStyles } from './styles';

const Pronunciation = ({pronunciation}) => {
  const classes = pronunciationStyles();
  const [recordings, setRecordings] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [showPractice, setShowPractice] = useState(false);

  const addRecording = (recording) => {
    setRecordings([...recordings, recording]);
  }

  const toggleHistory = () => {
    setShowHistory(!showHistory);
  }

  const togglePractice = () => {
    setShowPractice(!showPractice);
  }

  useEffect(() => {
    console.log(recordings);
  }, [recordings]);

  return (
    <Card className={classes.root}>
      <CardActions className={classes.actions} disableSpacing>
        <AudioPlayer url={pronunciation.url} />
        <span>{pronunciation.name}</span>
        <Button
          className={classes.expand}
          onClick={togglePractice}
          endIcon={showPractice ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        >
          Practice
        </Button>
      </CardActions>
      <Collapse in={showPractice}>
        <Recorder {...{ addRecording }} />
        <History {...{ showHistory, toggleHistory, recordings }} />
      </Collapse>
    </Card>
  );
}

export default Pronunciation;

import React from 'react';
import { useParams } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import VideoHistory from 'components/YouTube/VideoHistory';
import VideoPlayer from 'components/YouTube/VideoPlayer';
import VideoSearch from 'components/YouTube/VideoSearch';

const YouTube = () => {
  const params = useParams();
  const { id } = params;

  return (
    <Container maxWidth="xl">
      <VideoSearch />
      {id ? <VideoPlayer videoId={id} /> : <VideoHistory />}
    </Container>
  );
}

export default YouTube;

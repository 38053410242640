import React, { useState, useEffect } from 'react';
import dayjs from 'dayjs';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardHeader from '@material-ui/core/CardHeader';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';
import DeleteIcon from '@material-ui/icons/Delete';
import TextField from '@material-ui/core/TextField';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import Recorder from 'components/Recorder';
import AudioPlayer from 'components/AudioPlayer';
import { clipStyles } from './styles';

const Clip = ({ clip, playClip, pause, handleUpdate, handleRemove }) => {
  const classes = clipStyles();
  const [name, setName] = useState(clip.name);
  const [start, setStart] = useState(clip.start);
  const [end, setEnd] = useState(clip.end);
  const [recordings, setRecordings] = useState([]);
  const [showHistory, setShowHistory] = useState(false);
  const [showPractice, setShowPractice] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const setClipName = (e) => {
    setName(e.target.value);
  }

  const setClipStart = (e) => {
    const time = e.target.value;
    setStart(time);
  }

  const setClipEnd = (e) => {
    const time = e.target.value;
    setEnd(time);
  }

  const handlePlay = () => {
    playClip(start, end)
  }

  const addRecording = (recording) => {
    setRecordings([recording, ...recordings]);
  }

  const togglePractice = () => {
    setShowPractice(!showPractice);
  }

  const toggleHistory = () => {
    setShowHistory(!showHistory);
  }

  const handleMenuOpenClick = (evt) => {
    setAnchorEl(evt.currentTarget);
  }

  const handleMenuClose = () => {
    setAnchorEl(null);
  }

  const removeClip = () => {
    handleRemove(clip.id);
  }

  useEffect(() => {
    // TODO: Name is not updating...
    handleUpdate(clip.id, name, start, end);
  }, [clip.id, name, start, end, handleUpdate])

  const mostRecentRecording = recordings[0];

  return (
    <Card className={classes.root}>
      <CardHeader
        action={
          <>
            <IconButton className={classes.menu} onClick={handleMenuOpenClick}>
              <MoreVertIcon />
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={removeClip}>
                <ListItemIcon>
                  <DeleteIcon />
                </ListItemIcon>
                <Typography variant='inherit'>Delete Clip</Typography>
              </MenuItem>
            </Menu>
          </>
        }
        title={
          <TextField
            fullWidth
            onChange={setClipName}
            value={name}
            InputLabelProps={{ shrink: true }}
            placeholder={`Clip ${clip.id}`}
          />
        }
      />
      <CardActions className={classes.actions} disableSpacing>
        <div className={classes.controls}>
          <IconButton onClick={handlePlay}>
            <PlayArrowIcon />
          </IconButton>
          <IconButton onClick={pause}>
            <StopIcon />
          </IconButton>
        </div>
        <div className={classes.times}>
          <TextField
            id={`start-time-${start}-${end}`}
            className={classes.input}
            onChange={setClipStart}
            value={start}
            label="Clip Start"
            type="text"
          />
          <TextField
            id={`end-time-${start}-${end}`}
            className={classes.input}
            onChange={setClipEnd}
            value={end}
            label="Clip End"
            type="text"
          />
        </div>
        <div className={classes.practice}>
          <Button
            onClick={togglePractice}
            endIcon={showPractice ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          >
            Practice
          </Button>
        </div>
      </CardActions>
      <Collapse in={showPractice}>
        <CardActions className={classes.content} disableSpacing>
          <Recorder {...{ addRecording }} />
          {recordings.length
            ? <>
                <AudioPlayer url={mostRecentRecording.url} />
                { dayjs(mostRecentRecording.date).format('HH:mm:ss') }
              </>
            : '<-- Click to record'
          }
          {recordings.length > 1 &&
            <Button onClick={toggleHistory}>
              {showHistory ? 'Hide Earlier' : 'Show Earlier'}
            </Button>
          }
        </CardActions>
        {showHistory &&
          recordings.map((recording, i) => {
            if (i !== 0) {
              return (
                <div key={i} className={classes.history}>
                  <AudioPlayer url={recording.url} />
                  { dayjs(recording.date).format('HH:mm:ss') }
                </div>
              )
            }
            return null;
          })
        }
        </Collapse>
    </Card>
  );
}

export default Clip;

import Dexie from 'dexie';
import { Videos as SEED_VIDEOS } from './SeedData';
import { Clips as SEED_CLIPS } from './SeedData';

const db = new Dexie('pronunciate');

db.version(2).stores({
    clips: `++id, name, videoId, start, end`,
    videos: `++id, name, videoId`,
});

db.on('populate', () => {
    db.videos.bulkAdd(SEED_VIDEOS);
    db.clips.bulkAdd(SEED_CLIPS);
});

export default db;

import { makeStyles } from '@material-ui/core/styles';

export const pronunciationStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  actions: {
    display: 'flex',
  },
  expand: {
    marginLeft: 'auto',
  }
}));
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { useStyles } from './styles';

function VideoSearch() {
  const classes = useStyles();
  const history = useHistory();
  const [url, setUrl] = useState('');

  const getVideo = (e) => {
    e.preventDefault();
    const videoId = url.match(/(?:https?:\/\/)?(?:www\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\/?\?v=|\/embed\/|\/)([^\s&]+)/)[1];
    history.push(`/youtube/${videoId}`);
    setUrl('');
  }

  const handleChange = (e) => {
    setUrl(e.target.value);
  }

  return (
    <form className={classes.root} onSubmit={getVideo}>
      <TextField
        className={classes.input}
        onChange={handleChange}
        value={url}
        label="YouTube URL"
        variant="outlined"
        autoFocus
      />
      <Button
        className={classes.button}
        type="submit"
        variant="contained"
        color="primary"
      >
        Get Video
      </Button>
    </form>
  );
}

export default VideoSearch;

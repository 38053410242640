import React, { useState, useRef } from 'react';
import IconButton from '@material-ui/core/IconButton';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import StopIcon from '@material-ui/icons/Stop';

const AudioPlayer = ({ url }) => {
  const [playing, setPlaying] = useState(false);
  const audioEl = useRef(null);

  const handleClick = () => {
    if (!playing) {
      audioEl.current.play();
      setPlaying(true);
    } else {
      handlePlayEnd();
    }
  }

  const handlePlayEnd = () => {
    audioEl.current.pause();
    audioEl.current.currentTime = 0;
    setPlaying(false);
  }

  return (
    <>
      <audio ref={audioEl} src={url} onEnded={handlePlayEnd}/>
      <IconButton onClick={handleClick}>
        { !playing
          ? <PlayArrowIcon />
          : <StopIcon />
        }
      </IconButton>
    </>
  );
}

export default AudioPlayer;

import React from 'react';
import { Link as RouterLink } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Link from "@material-ui/core/Link";
import { useLiveQuery } from "dexie-react-hooks";
import CardTitle from './CardTitle';
import CardInfo from './CardInfo';
import db from "db";

export default function VideoHistory() {
  const videoHistory = useLiveQuery(() => db.videos.toArray(), [], []);

  if (!videoHistory) return null;

  return (
    <>
      <Typography variant="body1">Videos with saved clips</Typography>
      <Grid container spacing={3}>
        {videoHistory?.map(({ videoId, name }, i) => (
          <Grid item style={{ display: "flex" }} xs={12} sm={6} md={4} key={i}>
            <Card>
              <Link
                component={RouterLink}
                to={`/youtube/${videoId}`}
                color="primary"
                underline="none"
              >
                <CardActionArea>
                  <CardMedia
                    style={{ height: 202 }}
                    image={`https://img.youtube.com/vi/${videoId}/0.jpg`}
                    title={`${name} thumbnail`}
                  />
                  <CardContent style={{ maxHeight: "10em" }}>
                    <CardTitle name={name}/>
                    <CardInfo videoId={videoId} />
                  </CardContent>
                </CardActionArea>
              </Link>
            </Card>
          </Grid>
        ))}
      </Grid>
    </>
  );
}

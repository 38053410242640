import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  player: {
    marginBottom: theme.spacing(3),
    height: '310px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'lightgrey',
  },
}));
import React from 'react';
import dayjs from 'dayjs';
import Typography from '@material-ui/core/Typography';
import Recordings from './Recordings'
import AudioPlayer from 'components/AudioPlayer';
import {useStyles} from './styles';

const History = ({showHistory, toggleHistory, recordings}) => {
  const classes = useStyles();
  const mostRecentRecording = recordings[0];

  const timeStamp = () => {
    return dayjs(mostRecentRecording.date).format('HH:mm:ss')
  }

  if (showHistory) {
    return (
      <>
        <AudioPlayer url={mostRecentRecording.url} />
        <Typography variant='body1' className={classes.timeStamp}>
          {timeStamp()}
        </Typography>
        <span className={classes.toggleHistory} onClick={toggleHistory}>
          hide history
        </span>
        <Recordings {...{recordings}} />
      </>
    )
  }

  if (!showHistory && recordings.length) {
    return (
      <>
        <AudioPlayer url={mostRecentRecording.url} />
        <Typography variant='body1' className={classes.timeStamp}>
          {timeStamp()}
        </Typography>
        <span className={classes.toggleHistory} onClick={toggleHistory}>
          show history
        </span>
      </>
    );
  }

  return <span>Click to record yourself</span>;
}

export default History;

import React, { useState } from 'react';
import Container from '@material-ui/core/Container';
import Pronunciations from 'components/Wiktionary/Pronunciations';
import WordSearch from 'components/Wiktionary/WordSearch';

const Wikimedia = () => {
  const [pronunciations, setPronunciations] = useState([]);

  return (
    <Container maxWidth="sm">
      <WordSearch {...{setPronunciations}}/>
      <Pronunciations {...{pronunciations}} />
    </Container>
  );
}

export default Wikimedia;

export const Videos = [
  {
    videoId: '4-eDoThe6qo',
    name: 'Deutsch lernen (A1): Ganzer Film auf Deutsch - "Nicos Weg" | Deutsch lernen mit Videos | Untertitel',
  },
  {
    videoId: 'bLFlyCILkG8',
    name: '100 Phrases Every German Beginner Must-Know',
  },
];

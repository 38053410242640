import React from 'react';
import AudioPlayer from 'components/AudioPlayer';

const Recordings = ({recordings}) => {
  return (
    <ul>
      {
        recordings.map(recording => (
          <li key={recording.id}>
            <AudioPlayer url={recording.url} />
          </li>
        ))
      }
    </ul>
  );
}

export default Recordings;

import React from "react";
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import Typography from "@material-ui/core/Typography";
import { Tooltip } from '@material-ui/core';
import { makeStyles } from "@material-ui/core";
import { useLiveQuery } from "dexie-react-hooks";
import db from "db";

export default function CardInfo({ videoId }) {
  return (
    <div style={{ display: "flex", gap: "2em" }}>
      <Clips videoId={videoId} />
      <Recordings videoId={videoId} />
    </div>
  );
}

function Clips({ videoId }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      gap: "0.5em",
    },
    icon: {
      color: "red",
    },
  }));
  const classes = useStyles();
  const clipCount = useLiveQuery(() => db.clips.where({ videoId }).count());
  return (
    <Tooltip title="Clips">
      <div className={classes.root}>
        <VideoLibraryIcon className={classes.icon} color="primary" />
        <Typography variant="body2" color="textSecondary">
          {clipCount}
        </Typography>
      </div>
    </Tooltip>
  );
}

function Recordings({ videoId }) {
  const useStyles = makeStyles((theme) => ({
    root: {
      display: "flex",
      gap: "0.5em",
    },
    icon: {
        color: theme.palette.primary.main,
    }
  }));
  const classes = useStyles();
  const recordingsCount = 0;
  return (
    <Tooltip title="Recordings">
      <div className={classes.root}>
        <RecordVoiceOverIcon className={classes.icon} />
        <Typography variant="body2" color="textSecondary">
          {recordingsCount}
        </Typography>
      </div>
    </Tooltip>
  );
}
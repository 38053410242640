const { YT } = window;

class YouTubePlayer {
  constructor() {
    this.player = null;
    this.videoId = null;
    this.currentTimeout = null;
    this.playListener = null;
    this.pauseListener = null;
  }

  initialize({ videoId, container }) {
    this.videoId = videoId;
    YT.ready(() => {
      const ytPlayer = new YT.Player(container, {
        width: '100%',
        videoId: this.videoId,
        playerVars: {
          playsinline: 1
        },
        events: {
          'onReady': this.handlePlayerReady,
          'onStateChange': this.handlePlayerStateChange
        }
      });
      this.player = ytPlayer;
    });
  }

  unload() {
    if (this.player) {
      this.player.destroy();
      this.player = null;
    }
  }

  loadVideoById = (videoId) => {
    this.player.loadVideoById(videoId);
    this.stop();
  }

  handlePlayerReady = (e) => {
    console.log('onPlayerReady', e);
  }

  handlePlayerStateChange = (evt) => {
    if (evt.data === YT.PlayerState.PLAYING) {
      if (this.playListener) this.playListener();
    }
    if (evt.data === YT.PlayerState.PAUSED) {
      if (this.pauseListener) this.pauseListener();
    }
  }

  play = () => {
    this.player.playVideo();
  }

  pause = () => {
    this.player.pauseVideo();
  }

  stop = () => {
    this.player.stopVideo();
  }

  playClip = (startTime, endTime) => {
    window.clearTimeout(this.currentTimeout);

    const startTimeSeconds = this.convertToSeconds(startTime);
    const endTimeSeconds = this.convertToSeconds(endTime);

    this.player.seekTo(startTimeSeconds);

    this.playListener = () => {
      const clipDuration = endTimeSeconds - startTimeSeconds;
      const timeOut = window.setTimeout(this.pause, clipDuration * 1000)
      this.currentTimeout = timeOut;
    };

    this.pauseListener = () => {
      window.clearTimeout(this.currentTimeout);
      this.currentTimeout = null;
      this.playListener = null;
    };

    this.play();
  }

  getCurrentTime = (additionalSeconds = 0) => {
    const currentTimeInSeconds = this.player.getCurrentTime() + additionalSeconds;
    return this.convertFromSeconds(currentTimeInSeconds)
  }

  convertToSeconds = (time) => {
    if (typeof time === 'number') return time;

    const segments = time.split(':');
    switch (segments.length) {
      case 3: {
        return Number(segments[0]) * 60 * 60 + Number(segments[1]) * 60 + Number(segments[2]);
      }
      case 2: {
        return Number(segments[0]) * 60 + Number(segments[1]);
      }
      case 1: {
        return Number(segments[0]);
      }
      default: {
        return 0;
      }
    }
  }

  convertFromSeconds = (seconds) => {
    const [index, charLength] = seconds >= 3600 ? [11, 8] : [14, 5];
    return new Date(seconds * 1000).toISOString().substr(index, charLength);
  }

}

export default new YouTubePlayer();

import React, { useCallback, useEffect, useRef } from 'react';
import Clips from 'components/YouTube/Clips';
import YTPlayer from 'components/YouTube/YouTubePlayer';
import { useStyles } from './styles';

export default function VideoPlayer({ videoId }) {
  const classes = useStyles();
  const playerRef = useRef(null);

  const playClip = (startTime, endTime) => {
    YTPlayer.playClip(startTime, endTime);
  }

  const pause = () => {
    YTPlayer.pause();
  }

  const initializeOrLoadPlayer = useCallback(() => {
    if (!YTPlayer.player) {
      YTPlayer.initialize({ videoId, container: playerRef.current });
    } else {
      YTPlayer.loadVideoById(videoId);
    }
  }, [videoId, playerRef]);

  useEffect(() => {
    if (videoId) {
      initializeOrLoadPlayer();
    }

    return () => {
      YTPlayer.unload();
    };
  }, [videoId, initializeOrLoadPlayer]);

  //TODO: Maybe add setStart and setEnd buttons to player?

  return (
    <>
      <div id="player" ref={playerRef} className={classes.player}></div>
      <Clips {...{ videoId, playClip, pause }} />
    </>
  );
}
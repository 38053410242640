import React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import Header from 'components/Header';
import YouTube from 'containers/YouTube';
import Wiktionary from 'containers/Wiktionary';

function App() {
  return (
    <Router>
      <Header />
      <Switch>
        <Route exact path="/" component={YouTube} />
        <Route exact path="/youtube/:id" component={YouTube} />
        <Route exact path="/wiktionary" component={Wiktionary} />
      </Switch>
    </Router>
  );
}

export default App;

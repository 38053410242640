import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import { MuiThemeProvider, unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';
import { blue, pink } from '@material-ui/core/colors';
import CssBaseline from '@material-ui/core/CssBaseline';
import App from 'containers/App';

const theme = createMuiTheme({
  palette: {
    primary: blue,
    secondary: pink,
  },
});


ReactDOM.render(
  <StrictMode>
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <App />
    </MuiThemeProvider>
  </StrictMode>,
  document.getElementById('root')
);

import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { useStyles } from './styles';

const WordSearch = ({setPronunciations}) => {
  const classes = useStyles();
  const [word, setWord] = useState('');
  const [error, setError] = useState(null);

  const handleOnChange = (e) => {
    setWord(e.target.value);
  }

  const getWord = (e) => {
    e.preventDefault();
    setError(null);
    const url = queryURL(word);
    axios.get(url)
      .then((response) => {
        if (response.data.query) {
          const files = response.data.query.pages;
          setPronunciations(parseFiles(files));
        } else {
          setPronunciations([]);
          throw new Error('Word not found :-(');
        }
      })
      .catch((error) => {
        console.log(error);
        setError(error.message);
      });
  }

  const queryURL = (word) => {
    const endpoint = 'https://de.wiktionary.org/w/api.php';
    const params = [
      'action=query',
      'format=json',
      'prop=info%7Cimageinfo',
      `titles=${word}`,
      'generator=images',
      'inprop=url',
      'iiprop=url',
      'origin=*',
    ];
    return `${endpoint}?${params.join('&')}`;
  }

  const parseFiles = (files) => {
    const pronunciations = [];
    const filesLength = Object.keys(files).length;
    for (let i = 1; i < filesLength; i += 1) {
      const file = files[`-${i}`];
      const type = file.title.split('.')[1].toLowerCase();
      const language = file.title.split(':')[1].split('-')[0].toLowerCase();
      if (type === 'ogg' && language === 'de') {
        const id = i;
        const name = getName(file);
        const region = getRegion(file);
        const url = file.imageinfo[0].url;
        const pronunciation = { id, name, url, region };
        pronunciations.push(pronunciation);
      }
    }
    return pronunciations;
  }

  const getName = (file) => {
    // Title Format: 'Datei:De-meiner.ogg'
    // Title with region specified: 'Datei:De-at-Meinung.ogg'
    // Title with multiple words: 'Datei:De-die eigene Meinung.ogg'
    const name = file.title.split(':')[1].split('-').pop().split('.')[0];
    return name;
  }
  const getRegion = (file) => {
    const splitTitle = file.title.split(':')[1].split('-');
    let region;
    if (splitTitle.length > 2) {
      region = splitTitle[1].toUpperCase();
    }
    return region;
  }

  return (
    <div className={classes.root}>
      <form className={classes.form} onSubmit={getWord}>
        <TextField
          className={classes.input}
          onChange={ handleOnChange }
          value={ word }
          label="Word"
          variant="outlined"
          autoFocus
        />
        <Button
          className={classes.button}
          type="submit"
          variant="contained"
          color="primary"
        >
          Get Pronunciations
        </Button>
      </form>
      <div className="no-word">
        { error }
      </div>
    </div>
  )
}

export default WordSearch;

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(() => ({
  root: {
    marginTop: '1em',
    marginBottom: '2em',
  },
  title: {
    fontWeight: '300',
  },
}));
